import React from "react"
import SEO from "../components/utility/SEO"
import {makeStyles} from "@material-ui/core/styles";
import {graphql} from "gatsby";
import Grid from "@material-ui/core/Grid";
import ImageDialog from "../components/ImageDialog";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(() => ({
  imageDialog: {
    width: '100%',
    maxWidth: 'unset'
  }
}));

const Deals = ({data}) => {
  const classes = useStyles();

  if (!data || !data.prismicDeals) {
    return ''
  }

  const {data: pageData} = data.prismicDeals

  return (
    <>
      <SEO title="Προσφορές"/>
      <Container maxWidth={"lg"}>
        <Grid container justify={"space-around"} style={{marginBottom: 8}}>
          {pageData.product_deals_group && pageData.product_deals_group.map((deal, index) => {
            const dealData = deal.product_deals.document.data
            return (
              <Grid item style={{padding: 24}} xs={12} md={6} key={index}>
                <ImageDialog
                  image={dealData.image}
                  text={dealData.title}
                  className={classes.imageDialog}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}

export default Deals

export const DealsPageQuery = graphql`
  query DealsPage {
    prismicDeals {
      data {
        product_deals_group {
          product_deals {
            document {
              ... on PrismicProduct {
                id
                data {
                  title
                  category
                  image {
                    url
                    fluid {
                      srcWebp
                      srcSetWebp
                      srcSet
                      src
                      sizes
                      base64
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
